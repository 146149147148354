import Vue from "vue";
const jsSHA = require("jssha");
const OSS = require("ali-oss");
import dayjs from "dayjs";
import { mapState, mapMutations } from "vuex";
Vue.mixin({
  data() {
    return {};
  },
  computed: {
    ...mapState([
      "corpId",
      "userId",
      "appId",
      "detailInfo",
      "qrCodeContent",
      "JsAPITicket",
      "JsAPITicketAgent",
      "pattern",
      "time",
      "cmToken",
      "myInfo",
      "newClientData",
      "customersData",
      "pendTypedata",
      "LinkCustomer",
      "cacheList",
      "securityToken",
      "accessKeyId",
      "accessKeySecret",
    ]),
  },
  methods: {
    ...mapMutations([
      "changeCorpId",
      "changeUserId",
      "changeAppId",
      "changeDetailInfo",
      "changeQrCodeContent",
      "changeJsAPITicket",
      "changeJsAPITicketAgent",
      "changeTime",
      "changePattern",
      "changeCmToken",
      "changeMyInfo",
      "changeNewClientData",
      "changeCustomersData",
      "changeLinkCustomer",
      "changeCacheList",
      "changeSecurityToken",
      "changeAccessKeyId",
      "changeAccessKeySecret",
    ]),

    //获取地址栏参数
    getQueryString(name, link) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = link.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    thousand(num) {
      const regular = /\d(?=(\d{3})+$)/g
      if (isNaN(Number(num))) {
        return num
      }
      if (num.toString().indexOf('.') > -1) {
        const val = num.toString().split('.')
        return (val[0] + "").replace(regular, "$&,") + `.${val[1]}`
      }
      return (num + "").replace(regular, "$&,")
    },
    //判断微信环境
    getIsWxClient() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      }
      return false;
    },
    handleRingUp(phone) {
      if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(phone)) {
        window.location.href = `tel:${phone}`;
        return;
      }
      this.$toast.fail("手机号不完整");
    },
    //上传文件或者图片
    uploading(file) {
      file.status = "uploading";
      file.message = "上传中...";
      const client = new OSS({
        region: "oss-cn-shanghai",
        accessKeyId: this.accessKeyId,
        accessKeySecret: this.accessKeySecret,
        stsToken: this.securityToken,
        bucket: "geimgs",
      });
      const fileN = file.content;
      const fileU = file.file.name;
      const base64 = fileN.split(",").pop();
      const fileType = fileN.split(";").shift().split(":").pop();
      const blob = this.toBlob(base64, fileType);
      return this.readFile(blob).then((res) => {
        const fileExtension = fileU.substring(fileU.lastIndexOf("."));
        const objectKey =
          dayjs(new Date()).format("YYYYMMDD") +
          "/" +
          this.uuid() +
          fileExtension;
        const buffer = new OSS.Buffer(res.target.result);
        return client.put(objectKey, buffer);
      });
    },
    //获取sts
    async getStS() {
      const {
        data: { credentials },
        errcode,
      } = await this.$ajax.getStS();
      if (!errcode) {
        const { accessKeyId, accessKeySecret, securityToken } = credentials;
        this.changeSecurityToken(securityToken);
        this.changeAccessKeyId(accessKeyId);
        this.changeAccessKeySecret(accessKeySecret);
      }
      return null;
    },
    //转bolb对象
    toBlob(urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], { type: fileType });
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          resolve(event);
        };
      });
    },
    uuid() {
      //生成随机数
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      var uuid = s.join("");
      return uuid;
    },
    /**
     * 参数ASCII排序
     * @param obj 参数
     */
    sort_ascii(obj) {
      let arr = [];
      let num = 0;
      for (let i in obj) {
        arr[num] = i;
        num++;
      }
      let sortArr = arr.sort();
      let str = ""; //自定义排序字符串
      for (let i in sortArr) {
        str += sortArr[i] + "=" + obj[sortArr[i]] + "&";
      }
      //去除两侧字符串
      let char = "&";
      str = str.replace(
        new RegExp("^\\" + char + "+|\\" + char + "+$", "g"),
        ""
      );
      return str;
    },
    /**
     * Sha加密库
     * @param params 排序后的参数
     * @param way 加密方式例如SHA-1
     */
    Sha(params, way) {
      const shaObj = new jsSHA(way, "TEXT", { encoding: "UTF8" });
      const paramsAscii = this.sort_ascii(params);
      shaObj.update(paramsAscii);
      return shaObj.getHash("HEX");
    },
    /**
     * 获取企业应用信息
     * @param ticketType 权限申请方案
     */
    async getInfo(ticketType) {
      const result = await this.$ajax.getDeploy({
        corpId: this.corpId,
        ticketType,
        url: window.location.origin + '/' + window.location.search,
        // suiteName: ""
        suiteName: "第三方应用",
      });
      return { ticketType, result };
    },
    async uploadFile(File) {
      return await this.$ajax.uploadFile(File);
    },
    uploadFiles(File) {
      return this.$ajax.uploadFile(File);
    },
    //进入应用日志
    async logSave(corpId, wecomUserId, type, desc) {
      const res = await this.$ajax.logSave({
        corpId,
        wecomUserId,
        type,
        // desc,
      });
      console.log(res);
    },
    /**
     * 通过config接口注入权限验证配置
     * @param jsApiListConfig 需要使用的JS接口列表
     */
    getConfig(jsApiListConfig) {
      const { appId, timestamp, nonceStr, signature } = this.JsAPITicket;
      wx.config({
        // beta: true,
        debug: false, // 开启调试模式
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: jsApiListConfig, // 必填
      });
      //config配置成功后触发ready
      wx.ready(() => {
        let send = false;
        setTimeout(async () => {
          const { agentId, appId, timestamp, nonceStr, signature } =
            this.JsAPITicketAgent;
          // console.log(agentId);
          wx.agentConfig({
            corpid: appId,
            agentid: '1000156',
            timestamp,
            nonceStr,
            signature,
            jsApiList: jsApiListConfig,
            success: (res) => {
              wx.hideAllNonBaseMenuItem();
              wx.invoke("getContext", {}, (res) => {
                const { err_msg, entry } = res;
                if (err_msg == "getContext:ok") {
                  //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
                  // shareTicket = res.shareTicket; //可用于调用getShareInfo接口
                  console.log(entry, "入口详情");
                  switch (entry) {
                    case "normal":
                      this.logSave(appId, this.userId, 0, "");
                      break;
                    case "single_chat_tools":
                      this.logSave(appId, this.userId, 1, "");
                      break;
                    case "group_chat_tools":
                      this.logSave(appId, this.userId, 1, "");
                      break;
                  }
                } else {
                  //错误处理
                }
              });
            },
            fail: function (res) {
              console.log(res);
              if (res.errMsg.indexOf("function not exist") > -1) {
                alert("版本过低请升级");
              }
            },
          });
        }, 100);
      });
      //config配置失败后触发error
      wx.error(function (res) {
        const { errMsg } = res;
        console.log("企业微信config失败" + JSON.stringify(res));
        if (errMsg === "config:fail") {
          // Dialog.alert({
          //     title: '温馨提示',
          //     message: 'config配置失败',
          // }).then(() => {
          //     // on close
          // });
        }
      });

      if (window.location.hash === "#/")
        this.$router.push({ name: "homepage" });
    },
  },
  mounted() {
    // console.log(this.userId);
  },
});
