
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        primary: true,
        title: '首页',
        component: () => import('../views/homepage/index.vue'),

      },
      {
        path: '/client',
        name: 'client',
        primary: true,
        title: '客户',
        component: () => import('../views/client/index.vue'),
        meta:{
          keepAlive:true
        }
      },
      {
        path: '/information',
        name: 'information',
        primary: true,
        title: '数据',
        component: () => import('../views/information/index.vue'),

      },
      {
        path: '/dynamic',
        name: 'dynamic',
        primary: true,
        title: '动态',
        component: () => import('../views/dynamic/index.vue'),

      },
      {
        path: '/mine',
        name: 'mine',
        primary: true,
        title: '我的',
        component: () => import('../views/mine/index.vue'),

      },
      {
        path: '/broaden/welcome',
        name: 'welcome',
        primary: false,
        title: '个人欢迎语',
        component: () => import('../views/homepage/broaden/welcome.vue'),

      },
      {
        path: '/broaden/material',
        name: 'material',
        primary: false,
        title: '素材库',
        component: () => import('../views/homepage/broaden/material.vue'),

      },
      {
        path: '/broaden/addmaterial/article',
        name: 'article',
        primary: false,
        title: '添加文章',
        component: () => import('../views/homepage/broaden/addmaterial/AddArticle.vue'),

      },
      {
        path: '/broaden/addmaterial/addImg',
        name: 'addImg',
        primary: false,
        title: '添加图片',
        component: () => import('../views/homepage/broaden/addmaterial/AddImg.vue'),

      },
      {
        path: '/broaden/addmaterial/addVideo',
        name: 'addVideo',
        primary: false,
        title: '添加视频',
        component: () => import('../views/homepage/broaden/addmaterial/AddVideo.vue'),

      },
      {
        path: '/broaden/addmaterial/addFile',
        name: 'addFile',
        primary: false,
        title: '添加文件',
        component: () => import('../views/homepage/broaden/addmaterial/AddFile.vue'),

      },
      {
        path: '/broaden/addmaterial/addLink',
        name: 'addLink',
        primary: false,
        title: '添加网页',
        component: () => import('../views/homepage/broaden/addmaterial/AddLink.vue'),

      },
      {
        path: '/follow',
        name: 'follow',
        primary: false,
        title: '',
        component: () => import('../views/homepage/follow/index.vue'),
      },
      {
        path: '/clientData',
        name: 'clientData',
        primary: false,
        title: '客户档案',
        component: () => import('../views/dynamic/clientData/index.vue'),
      },
      {
        path: '/particulars',
        name: 'particulars',
        primary: false,
        title: '素材详情',
        component: () => import('../views/homepage/broaden/particulars/index.vue'),
      },
      {
        path: '/articleContent',
        name: 'articleContent',
        primary: false,
        title: '',
        component: () => import('../views/articleContent/index.vue'),
      },
      {
        path: '/smartCode',
        name: 'smartCode',
        primary: false,
        title: '智能码',
        component: () => import('../views/homepage/smartCode/index.vue'),
      },
      {
        path: '/addCode',
        name: 'addCode',
        primary: false,
        title: '新增智能码',
        component: () => import('../views/homepage/smartCode/addCode.vue'),
      },
      {
        path: '/codeDetails',
        name: 'codeDetails',
        primary: false,
        title: '智能码详情',
        component: () => import('../views/homepage/smartCode/codeDetails.vue'),
      },
      {
        path: '/followup',
        name: 'followup',
        primary: false,
        title: '新增跟进',
        component: () => import('../views/dynamic/clientData/followup.vue'),
      },
      {
        path: '/Vbuildings',
        name: 'Vbuildings',
        primary: false,
        title: '查看楼栋',
        component: () => import('../views/homepage/buildings/Vbuildings.vue'),
      },
      {
        path: '/ListingSetting',
        name: 'ListingSetting',
        primary: false,
        title: '房源设置',
        component: () => import('../views/homepage/buildings/ListingSetting.vue'),
      },
      {
        path: '/ListingDetails',
        name: 'ListingDetails',
        primary: false,
        title: '房源明细',
        component: () => import('../views/homepage/buildings/ListingDetails.vue'),
      },
      {
        path: '/HInformation',
        name: 'HInformation',
        primary: false,
        title: '房源信息',
        component: () => import('../views/homepage/buildings/HInformation.vue'),
      },
      {
        path: '/houseInformation',
        name: 'houseInformation',
        primary: true,
        title: '房源信息',
        component: () => import('../views/homepage/buildings/houseInformation.vue'),
      },
      {
        path: '/ListingScreen',
        name: 'ListingScreen',
        primary: false,
        title: '房源筛选',
        component: () => import('../views/homepage/buildings/ListingScreen.vue'),
      },
      {
        path: '/relationlist',
        name: 'relationlist',
        primary: false,
        title: '关联客户',
        component: () => import('../views/client/relationlist.vue'),
      },
      {
        path: '/relation',
        name: 'relation',
        primary: false,
        title: '搜索关联客户',
        component: () => import('../views/client/relation.vue'),
      },
      {
        path: '/Calculator',
        name: 'Calculator',
        primary: false,
        title: '房贷计算器',
        component: () => import('../views/mine/Calculator.vue'),
      },
      {
        path: '/rdepaymentdetails',
        name: 'rdepaymentdetails',
        primary: false,
        title: '还款详情',
        component: () => import('../views/mine/rdepaymentdetails.vue'),
      },
      {
        path: '/register',
        name: 'register',
        primary: false,
        title: '登记客户',
        component: () => import('../views/client/register.vue'),
        meta:{
          keepAlive:true
        }
      }
    ],
  },
  {
    path: '/reportForm',
    name: 'reportForm',
    component: ()=> import('../views/reportForm/index.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
