
import axios from 'axios'
import { Toast } from "vant"

export function showLoading(msg = '加载中...') {
    Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
    });
}

export function showSuccess(msg = '成功') {
    Toast.success(msg)
}

export function showFail(msg = '失败') {
    Toast.fail(msg)
}


axios.interceptors.request.use(function (config) {
    // console.log(config);
    showLoading()
    return config;
}, function (error) {

    // 请求发送失败 

    showFail('请求失败')
    return Promise.reject(error);
});



axios.interceptors.response.use(function (response) {
    Toast.clear();
    // console.log(response);
    return response;
}, function (error) {
    // 响应失败 

    showFail('服务器异常')
    return Promise.reject(error);
});

export function get(url, params, headers) {
    return new Promise(function (resolve, reject) {
        axios({
            url,
            method: "GET",
            params,
            headers,
        })
            .then(result => {
                resolve(result.data)   // axios 里面的 data 数据解析 
            })
            .catch(err => {
                reject(err)
            })
    })
}
// post
export function post(url, data, params, headers) {
    return new Promise(function (resolve, reject) {
        axios({
            url,
            method: "post",
            params,
            headers,
            data,   // POST 请求提交的数据 

        })
            .then(result => {
                resolve(result.data)   // axios 里面的 data 数据解析 
            })
            .catch(err => {
                reject(err)
            })
    })
}
// delete
export function Delete(url, params) {
    return new Promise(function (resolve, reject) {
        axios({
            url,
            method: "delete",
            params,
        })
            .then(result => {
                resolve(result.data)   // axios 里面的 data 数据解析 
            })
            .catch(err => {
                reject(err)
            })
    })
}
// patch
export function Patch(url, data) {
    return new Promise(function (resolve, reject) {
        axios({
            url,
            method: "patch",
            data
        })
            .then(result => {
                resolve(result.data)   // axios 里面的 data 数据解析 
            })
            .catch(err => {
                reject(err)
            })
    })
}


export {
    axios
}
