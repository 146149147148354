import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate"; // localStorage
export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    corpId: "",
    userId: "",
    //公众号appid
    appId: "wx2fc5573ccf87360a",
    time: "2022-01-01",
    detailInfo: "",
    qrCodeContent: "",
    JsAPITicket: {},
    JsAPITicketAgent: {},
    pattern: "concise",
    cmToken: "",
    myInfo: "",
    buildingdata: "", // 房源信息
    pendTypedata: "", // 客户状态列表信息
    isOld: "", // 客户列表状态
    LinkCustomer: "", // 关联客户参数
    newClientData: "",
    customersData: "",
    cacheList: ["Client","register"],
    securityToken:'',
    accessKeyId:"",
    accessKeySecret:""
  },
  getters: {},
  mutations: {
    changeAccessKeySecret(state, payload){
      state.accessKeySecret = payload;
    },
    changeAccessKeyId(state, payload){
      state.accessKeyId = payload;
    },
    changeSecurityToken(state, payload){
      state.securityToken = payload;
    },
    changeCacheList(state, payload) {
      state.cacheList = payload;
    },
    changeCustomersData(state, payload) {
      state.customersData = payload;
    },
    changeNewClientData(state, payload) {
      state.newClientData = payload;
    },
    changeMyInfo(state, payload) {
      state.myInfo = payload;
    },
    changeCmToken(state, payload) {
      state.cmToken = payload;
    },
    changePattern(state, payload) {
      state.pattern = payload;
    },
    changeTime(state, payload) {
      state.time = payload;
    },
    changeCorpId(state, payload) {
      state.corpId = payload;
    },
    changeUserId(state, payload) {
      state.userId = payload;
    },
    changeAppId(state, payload) {
      state.appId = payload;
    },
    changeDetailInfo(state, payload) {
      state.detailInfo = payload;
    },
    changeQrCodeContent(state, payload) {
      state.qrCodeContent = payload;
    },
    changeJsAPITicket(state, payload) {
      state.JsAPITicket = payload;
    },
    changeJsAPITicketAgent(state, payload) {
      state.JsAPITicketAgent = payload;
    },
    changebuildingdata(state, payload) {
      state.buildingdata = payload;
    },
    changependTypedata(state, payload) {
      state.pendTypedata = payload;
    },
    changeisOld(state, payload) {
      state.isOld = payload;
    },
    changeLinkCustomer(state, payload) {
      state.LinkCustomer = payload;
    },
  },
  actions: {},
  modules: {},
});
