import { get, post } from "./request";
//获取素材接口
//开发环境
// const KHT_baseURL = "http://kht.ha5fun.cn"
//开发环境
const KHT_baseURL = "http://scrm.gewings.cn/jkt";

//生产环境
// const KHT_baseURL = "https://kht.gewings.com";

//获取权限接口
const GET_ROOT = "http://scrm.gewings.cn";

//生产权限接口
// const GET_ROOT = "https://api-outer.luzhenm.com";

const WEIXIN = "https://open.weixin.qq.com/connect/oauth2/authorize";

//开发环境
const NODE_ANALYSIS = "http://node.ha5fun.cn";
//生成环境
// const NODE_ANALYSIS = "https://node.gewings.cn";

//开发环境营销云接口
const YXY_baseURL = "http://jp-dj-dev.gewings.com/api-xs";

//生产环境营销云接口
// const YXY_baseURL = "https://xs.gewings.cn";

const headers = () => {
  try {
    return {
      cmtoken: JSON.parse(localStorage.getItem("vuex"))["cmToken"],
    };
  } catch (error) {
    return {
      cmtoken: "",
    };
  }
};
export const ajax = {
  getDeploy: (params) => post(GET_ROOT + "/wecom/sign", "", params),

  getAppInfo: (params) =>
    post(GET_ROOT + "/server/outer/wecom/agentapp", "", params),

  getStaffIdInfo: (params) =>
    get(GET_ROOT + "/wecom/sidebar/staff", "", params),

  //获取素材列表
  getMaterials: (data) =>
    post(KHT_baseURL + "/qwkft/material/get_materials", data, ""),

  //添加文章素材
  addMaterialsArticle: (data) =>
    post(KHT_baseURL + "/qwkft/material/add_materials_article", data, ""),

  //添加图片素材
  addMaterialsImg: (data) =>
    post(KHT_baseURL + "/qwkft/material/add_materials_img", data, ""),

  //添加视频素材
  addMaterialsVideo: (data) =>
    post(KHT_baseURL + "/qwkft/material/add_materials_video", data, ""),

  //添加PDF素材
  addMaterialsPdf: (data) =>
    post(KHT_baseURL + "/qwkft/material/add_materials_pdf", data, ""),

  //添加网页素材
  addMaterialsLink: (data) =>
    post(KHT_baseURL + "/qwkft/material/add_materials_link", data, ""),

  getAnalysis: (params) => get(NODE_ANALYSIS + "/api/analysis", params),

  uploadChunkFile: (data) =>
    post(KHT_baseURL + "/qwkft/material/upload_chunk_file", data, "", {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    }),
  //客户通登陆
  getLogin: (params) =>
    get(KHT_baseURL + "/customer/wechat/login_url", params, {
      "Content-Type": "text/plain;charset=UTF-8",
      Accept: "*/*",
    }),
  //弹出微信授权窗口
  getWeiXinInfo: (url) => get(url),

  //增加阅读量
  addRead: (params) => get(KHT_baseURL + "/qwkft/material/share/views", params),

  //获取单独文章详情
  getArticleParticulars: (params) =>
    get(KHT_baseURL + "/qwkft/material/get_material_info", params),

  //微信公众号签名
  getWxSignature: (params) =>
    get(KHT_baseURL + "/customer/wechat/sdk_signature", params),

  //创建分享链路
  setCreate: (body) =>
    post(KHT_baseURL + "/qwkft/material/share_link/create", body),

  //添加分享链路

  addCreate: (body) =>
    post(KHT_baseURL + "/qwkft/material/share_link/add", body),

  //获取分享链路图表数据
  getShareList: (body) =>
    post(KHT_baseURL + "/qwkft/material/share_link/list", body, ""),

  //上传图片
  uploadFile: (body) =>
    post(KHT_baseURL + "/qwkft/material/upload_file", body, "", {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    }),

  //新建智能码
  qrCode: (body) => post(KHT_baseURL + "/qwkft/contact/qrcode", body, ""),

  //获取登陆者的详细信息
  getDetailInfo: (params) => get(KHT_baseURL + "/staff/user/get", params),

  //获取个人码/企业码列表
  getQrCodeList: (body) =>
    post(KHT_baseURL + "/qwkft/contact/qrcode_list", body),

  //获取欢迎语
  getWelcome: (params) =>
    get(KHT_baseURL + "/qwkft/contact/welcome/get", params),

  //创建欢迎语
  createWelcome: (body) =>
    post(KHT_baseURL + "/qwkft/contact/welcome/create", body),

  //文章留电
  intendedUserAdd: (body) =>
    post(KHT_baseURL + "/qwkft/contact/intended_user/add", body),

  //获取留电列表
  intendedUserList: (params) =>
    get(KHT_baseURL + "/qwkft/contact/intended_user/list", params),

  //浏览时长
  shareLinkTime: (body) =>
    post(KHT_baseURL + "/qwkft/material/share_link/views", body),

  //删除智能码
  qrCodeDel: (params) => get(KHT_baseURL + "/qwkft/contact/qrcode/del", params),

  //更新智能码
  qrCodeUpdate: (body) =>
    post(KHT_baseURL + "/qwkft/contact/qrcode/update", body),

  //注册时候获取验证码
  requestVerifyCode: (body) =>
    post(KHT_baseURL + "/staff/request_verify_code", body),

  //通过验证码绑定用户信息
  verifyAndLogin: (body) => post(KHT_baseURL + "/staff/verify_and_login", body),

  //获取STS
  getStS: () => post(GET_ROOT + "/server/outer/get_sts"),

  //获取用户列表
  getUserList: (params) => get(GET_ROOT + "/wecom/sidebar/user", params),

  //根据id获取智能码
  getQrCode: (params) =>
    get(KHT_baseURL + "/qwkft/material/qrcode/by_id", params),

  //营销云获取用户id
  getUserId: (body) => post(YXY_baseURL + "/xs_wxwork/sales_user_login", body),

  //通过企微返回code
  getUserIds: (body) => get(GET_ROOT + "/server/outer/wecom/user/getUserId", body),

  //我的客户列表查询
  getClientList: (body) =>
    post(YXY_baseURL + "/xs_wxwork/my_customers", body, "", headers()),

  //进入应用日志保存
  logSave: (body) => post(KHT_baseURL + "/qwkft/app/log/save", body),

  //获取客户登记项目参数配置
  getParameter: (body) =>
    post(YXY_baseURL + "/xs_wxwork/project_model", body, "", headers()),

  //获取客户登记接口查询项目必选参数接口
  getRequiredParameter: (body) =>
    post(YXY_baseURL + "/xs_wxwork/project_must_param", body, "", headers()),

  //营销云查询客户数量接口
  getCustomerConut: (body) =>
    post(YXY_baseURL + "/xs_wxwork/customer_count", body, "", headers()),

  //客户登记 --新增客户接口
  addCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/add_customer", body, "", headers()),

  //获取客户基础信息接口
  getCustomerBasic: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_customer_basic", body, "", headers()),

  //通过外部联系人userId获取客户手机号
  getExternalUserPhone: (params) =>
    get(
      KHT_baseURL + "/qwkft/contact/get/external_user_phone",
      params,
      "",
      headers()
    ),

  //绑定外部联系人手机号
  bindPhone: (body) =>
    post(KHT_baseURL + "/qwkft/contact/bind/phone", body, "", headers()),

  //通过手机号获取客户详情信息
  getCustomerBasicByTel: (body) =>
    post(
      YXY_baseURL + "/xs_wxwork/get_customer_basic_by_tel",
      body,
      "",
      headers()
    ),

  //获取客户跟进记录
  getCustomerFollowRecord: (body) =>
    post(
      YXY_baseURL + "/xs_wxwork/get_customer_follow_record",
      body,
      "",
      headers()
    ),

  //获取客户意向
  getCustomerIntention: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_customer_wishe", body, "", headers()),

  //获取客户资料
  getCustomerInfo: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_customer_info", body, "", headers()),

  //补全号码接口
  completingHiddenTel: (body) =>
    post(YXY_baseURL + "/xs_wxwork/completing_hidden_tel", body, "", headers()),

  //根据手机号获取客户userId
  getFollowUser: (body) =>
    post(
      "https://jy-pyj-test.gewings.com/server/outer/wecom/contact/follow_user/list",
      body
    ),

  //新增跟进记录
  addFollow: (body) =>
    post(YXY_baseURL + "/xs_wxwork/add_follow", body, "", headers()),

  //新增意向接口
  addIntention: (body) =>
    post(YXY_baseURL + "/xs_wxwork/addIntention", body, "", headers()),

  //签到接口
  setConsultantSign: (body) =>
    post(YXY_baseURL + "/xs_wxwork/set_consultant_sign", body, "", headers()),

  //获取认知途径
  getCognitionWay: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_cognition_way", body, "", headers()),

  //完善客户资料
  completeCustomerInfo: (body) =>
    post(
      YXY_baseURL + "/xs_wxwork/complete_customer_info",
      body,
      "",
      headers()
    ),

  //获取完善进度
  getImprovePerfection: (body) =>
    post(
      YXY_baseURL + "/xs_wxwork/get_improve_perfection",
      body,
      "",
      headers()
    ),

  //删除客户详情
  setInvalidCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/set_invalid_customer", body, "", headers()),

  //房源-查看用户所在项目接口
  getErpProjectList: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_erpProject_list", body, "", headers()),

  //房源-查看楼栋接口
  getBuildingList: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_building_list", body, "", headers()),

  //房源-查看单元接口
  getBuildingUnitList: (body) =>
    post(
      YXY_baseURL + "/xs_wxwork/get_building_Unit_list",
      body,
      "",
      headers()
    ),

  //房源-查看房源信息接口
  getRoomList: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_room_list", body, "", headers()),

  //房源-查询房源信息接口
  getQueryRoomList: (body) =>
    post(YXY_baseURL + "/xs_wxwork/get_query_room_list", body, "", headers()),

  //到访待跟进列表查询接口
  getArriveWait: (body) =>
    post(YXY_baseURL + "/xs_wxwork/arrive_wait_follow", body, "", headers()),

  //分配待跟进列表查询接口
  getDivideWait: (body) =>
    post(YXY_baseURL + "/xs_wxwork/divide_wait_follow", body, "", headers()),

  //老客户待跟进列表查询接口
  getCustomerWait: (body) =>
    post(YXY_baseURL + "/xs_wxwork/customer_wait_follow", body, "", headers()),

  //获取客户数
  getCustomerNum: (body) =>
    post(YXY_baseURL + "/xs_wxwork/pend_customer_num", body, "", headers()),

  //删除意向
  deletesCustomerNum: (body) =>
    post(YXY_baseURL + "/xs_wxwork/delIntention", body, "", headers()),

  //搜索已经关联的客户
  getQueryLinkCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/query_link_customer", body, "", headers()),

  //关联客户
  addLinkCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/add_link_customer", body, "", headers()),

  //取消关联客户
  delLinkCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/del_link_customner", body, "", headers()),

  //搜索可关联客户列表
  getFindLinkCustomer: (body) =>
    post(YXY_baseURL + "/xs_wxwork/find_link_customer", body, "", headers()),

  //切换项目
  salesChangeProject: (body) =>
    post(YXY_baseURL + "/xs_wxwork/sales_change_project", body, "", headers()),

};
