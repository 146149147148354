import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VConsole from 'vconsole';
import { ajax } from './api/index';
import {
  List, Empty, Divider, Col, Sticky, Swipe, Badge,Checkbox ,
  CheckboxGroup, SwipeItem, Row, Picker, Step, Cell, Steps,SwipeCell ,
  Progress, RadioGroup, Radio, ActionSheet, ShareSheet, DropdownMenu,
  DropdownItem, Tab, Tabs, Popover, Popup, Button, Search, Field, Icon,
  Toast, Tabbar, TabbarItem, Grid, GridItem, Image as VanImag, Tag, NavBar,
  NoticeBar, Form, CellGroup, DatetimePicker, Uploader, Collapse, CollapseItem, Overlay
} from 'vant';

import H5Cropper from "vue-cropper-h5";
import { Lazyload } from 'vant';
import './registerServiceWorker';
import './assets/font_3762895_2b1gme90gok/iconfont'
import "@/uilts/mixins";
import SvgIcon from '@/components/SvgIcon'// svg component
import '../src/assets/font_4ypyoztafnr/iconfont.css'
import './style/index.scss'
import * as echarts from 'echarts'
// vue复制库
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.prototype.$echarts = echarts
Vue.prototype.$ajax = ajax
const pluginsVant = [
  Cell, Swipe, SwipeItem, Badge,SwipeCell ,
  Overlay, CheckboxGroup, Col, Row,
  Collapse, CollapseItem, CellGroup, DatetimePicker,
  Step, Steps, RadioGroup, Radio,
  Progress, Empty, List, Sticky,
  Button, Field, Icon, Divider,Checkbox,
  Toast, Tabbar, TabbarItem, Grid,
  GridItem, VanImag, Tag, NavBar,
  NoticeBar, Form, Uploader, Popup,
  Popover, Picker, Search, ShareSheet,
  Tab, Tabs, DropdownMenu, DropdownItem, ActionSheet
]

// const vConsole = new VConsole();

const vantPlugins = {
  install: (vm) => {
    pluginsVant.forEach(item => {
      vm.component(item.name, item)
    })
  }
}



Vue.component('svg-icon', SvgIcon)
const req = require.context('../src/assets/icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

Vue.use(Lazyload, {
  lazyComponent: true
})

// Vue.use(vConsole)
Vue.use(vantPlugins)
Vue.use(H5Cropper)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
