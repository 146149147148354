<template>
  <div class="home">
    <keep-alive :include="cacheList" :max="2">
      <router-view ></router-view>
    </keep-alive>
    <!-- <router-view v-if="!$route.meta?.keepAlive"></router-view> -->

    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '90%' }"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      :safe-area-inset-bottom="true"
    >
      <van-nav-bar title="填写您的手机号" />

      <div class="logo">
        <van-image
          width="100"
          height="100"
          fit="cover"
          src="https://cloud-kefu-res.oss-cn-hangzhou.aliyuncs.com/img/2022/11/17/d23df2742087407f9294b5e34bc1dce717.jpg"
        />
      </div>

      <van-form @submit="onSubmit">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
          maxLength="11"
          @input="changePhone"
          :rules="[
            { required: true, message: '请填写手机号' },
            {
              pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
              message: '请输入正确手机号',
            },
          ]"
        />
        <van-field
          v-model="code"
          name="code"
          label="短信验证码"
          placeholder="请输入短信验证码"
          maxLength="6"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <span
              @click="!sendDisabled ? requestVerifyCode() : ''"
              :style="!sendDisabled ? { color: '#2c86f9' } : { color: '#ccc' }"
              >{{ codeTitle }}</span
            >
          </template>
        </van-field>
        <div style="margin: 30px 16px">
          <van-button
            round
            block
            color="#2c86f9"
            native-type="submit"
            :disabled="disabled"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <van-tabbar
      route
      active-color="#0053b7"
      inactive-color="#000"
      v-show="showTabBar"
    >
      <van-tabbar-item replace to="/homepage" >
        <span>首页</span>
        <template #icon="props">
          <SvgIcon
            :svgName="
              props.active ? 'icon-a-icon1115-08' : 'icon-a-icon1115-07'
            "
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item replace to="/client" @click="handleTo">
        <span>客户</span>
        <template #icon="props">
          <SvgIcon
            :svgName="
              props.active ? 'icon-a-icon1115-06' : 'icon-a-icon1115-05'
            "
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item replace to="/houseInformation">
        <span>房源</span>
        <template #icon="props">
          <SvgIcon
            :svgName="
              props.active ? 'icon-a-icon1115-02' : 'icon-a-icon1115-01'
            "
          />
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item replace to="/information"
        ><span>数据</span>
        <template #icon>
          <van-icon
            class="iconfont icon-shujukanban"
            class-prefix="icon"
            name="icon-shujukanban"
            size="20px"
          /> </template
      ></van-tabbar-item> -->

      <!-- <van-tabbar-item replace to="/dynamic"
        ><span>动态</span>
        <template #icon>
          <van-icon
            class="iconfont icon-dongtai"
            class-prefix="icon"
            name="icon-dongtai"
            size="20px"
          /> </template
      ></van-tabbar-item> -->

      <van-tabbar-item replace to="/mine">
        <span>我的</span>
        <template #icon="props">
          <SvgIcon
            :svgName="
              props.active ? 'icon-a-icon1115-04' : 'icon-a-icon1115-03'
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ajax } from "@/api";
import SvgIcon from "@/components/SvgIcon.vue";
import dayjs from "dayjs";
import Md5 from "js-md5";
export default {
  name: "HomeView",
  components: { SvgIcon },
  data() {
    return {
      jsApiListConfig: [
        "getCurExternalContact",
        "launchMiniprogram",
        "selectExternalContact",
        "onMenuShareAppMessage",
        "getContext",
        "shareAppMessage", //自定义转发到会话
        "selectCorpGroupContact",
        "shareToExternalMoments", //分享到客户朋友圈
        "openUserProfile",
      ],
      showTabBar: true,
      routerList: this.$router.options.routes[0]["children"],
      show: false,
      disabled: true,
      sendDisabled: false,
      phone: "",
      code: "",
      qwCode:'',
      codeTitle: "发送验证码",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { name } = newVal;
        this.routerList.forEach((item) => {
          if (item.name === name) {
            const { title, primary } = item;
            document.title = title;
            return (this.showTabBar = primary);
          }
          if (item.children) {
            item.children.forEach((items) => {
              if (items.name === name) {
                const { title, primary } = items;
                document.title = title;
                return (this.showTabBar = primary);
              }
            });
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit({ phone, code }) {
      this.disabled = true;
      ajax
        .verifyAndLogin({
          phone,
          verifyCode: code,
          thirdUserId: this.userId,
        })
        .then(({ errcode, errmsg, data }) => {
          if (!errcode) {
            const { corpId, wecomUserId } = data;
            this.changeDetailInfo(data);
            this.changeCorpId(corpId);
            this.changeUserId(wecomUserId);
            this.$toast.success("绑定成功");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return;
          }
          this.disabled = false;
          this.$toast.fail(errmsg);
        })
        .catch(() => {
          this.disabled = false;
        });
    },
    handleTo(){
      this.changeCacheList(["register"]);
    },

    changePhone(value) {
      value ? (this.disabled = false) : (this.disabled = true);
    },
    async requestVerifyCode() {
      if (this.phone && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.phone)) {
        this.sendDisabled = true;
        const { errcode, errmsg } = await ajax.requestVerifyCode({
          phone: this.phone,
        });
        if (!errcode) {
          this.$toast.success("发送成功");
          let count = 60;
          const Interval = setInterval(() => {
            count--;
            if (count <= 0) {
              this.sendDisabled = false;
              this.codeTitle = "发送验证码";
              clearInterval(Interval);
              return;
            }
            this.codeTitle = `${count}s`;
          }, 1000);
          return;
        }
        this.sendDisabled = false;
        this.$toast.fail(errmsg);
        return;
      }
      this.$toast.fail("手机号不正确");
    },
    async loginVerify() {
      try {
        const result = await Promise.all([
          this.getInfo("JSAPITicket"),
          this.getInfo("JSAPITicketAgent"),
        ]);
        result.forEach((item) => {
          switch (item["ticketType"]) {
            case "JSAPITicket":
              this.changeJsAPITicket(item["result"]["data"]);
              break;
            case "JSAPITicketAgent":
              this.changeJsAPITicketAgent(item["result"]["data"]);
              // console.log(item["result"]["data"]);
              break;
          }
        });
        this.changeTime(dayjs().add(1, "hour").toString());
        this.setUserDetailInfo();
      } catch (error) {
        console.log("登录错误:" + error);
        this.$toast.fail("登录错误:" + error);
      }
      this.getConfig(this.jsApiListConfig);
    },
    async setUserDetailInfo() {
      const { data, errcode } = await ajax.getDetailInfo({
        corpId: this.corpId,
        userId: this.userId,
      });
      if (!errcode) {
        this.changeDetailInfo(data);
        this.getUserId(data["mobile"]);
        return;
      }
      this.$toast.fail("用户信息获取失败");
    }, 
    async getUserId(mobile) {
      const eventTime = String(dayjs(new Date()).valueOf());
      const weWorkSecretKey = Md5(
        Md5("cae4750db79da82be6a8184e82cce560") +
          Md5(`7aaawni8e6w9gw8d5b4${eventTime}`)
      );
      let { success, msg, data } = await ajax.getUserId({
        // mobileTel: mobile || this.detailInfo["mobile"],
        mobileTel: "13317165553",
        weWorkSecretKey,
        eventTime,
      });
      this.changeCmToken("");
      this.changeMyInfo("");

      if (success) {
        let { cmtoken, myInfo, myProjects } = data;

        myInfo = { ...myInfo, myProjects };
        this.changeCmToken(cmtoken);
        this.changeMyInfo(myInfo);
        return
      }

      if (msg === "Token无效") {
        return;
      }
      this.$toast.fail(msg);
    },
    async getUserIds(code) {
      const {data ,errcode} = await ajax.getUserIds({code});
      if(!errcode){
        const { errcode , UserId} = JSON.parse(data)
        switch (errcode) {
          case 40029:
          this.$toast.fail("超时");
            wx.closeWindow()
            break;
          case 0:
            this.changeUserId(UserId)
            break;
        }
      }
    },
    getUrlAllParam(...args) {
       if (args.length === 0) return undefined
       const url = decodeURIComponent(window.location.href)
       const reg = args.length === 1
        ? new RegExp(`[&?]${args[0]}=([^&%#]+)`)
        : new RegExp(`[&?](?:${args.join('|')})=([^&%#]+)`)
       const matchArray = url.match(reg)
      return matchArray === null ? undefined : matchArray[1]
    }
    

  },
  async mounted() {
    //以后保留使用地址栏，目前用不上
    if (this.$route.name === "articleContent") return;
    const CorpId = this.getUrlAllParam('state')
    const qwCode = this.getUrlAllParam('code')
    console.log(CorpId,qwCode);
   if(qwCode && CorpId && !this.userId){
    this.qwCode = qwCode
    this.changeCorpId(CorpId)
    await this.getUserIds(qwCode)
   }

   this.loginVerify();
    // debugger
    // if (!this.detailInfo) {
    //   this.show = true;
    //   return;
    // }
    //获取企微企业权限
    // console.log(window.location);
  },
};
</script>

<style lang="scss" scoped>
.home {
  max-width: 677px;
  min-height: 100vh;
  margin: auto;
  background: #f7f7f7;
  .head-banner {
    display: flex;
    padding: 20px;
    color: white;
    background: url("https://scrm.gewings.cn/static/images/index/find_bg.png")
      no-repeat 100% 100%;
    height: 140px;

    .time {
      p {
        font-size: 20px;
      }

      p:nth-child(2) {
        font-size: 14px;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }

  .pad {
    padding: 20px;
  }

  .viewPage {
    padding-bottom: 70px;
    // height: 100%;
    background: #f2f2f2;
  }
}

// 淡出淡入
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
