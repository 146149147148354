<!-- svg组件 -->
<template>
  <svg class="icon" :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>
   
   <script>
export default {
  name: "SvgIcon",
  props: {
    // svg 的名称
    svgName: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName() {
      return `#${this.svgName}`;
    },
    svgClass() {
      if (this.svgName) {
        return "icon" + this.svgName;
      } else {
        return "icon";
      }
    },
  },
};
</script>
   
   <style lang="less" scoped>
.icon {
  width: 35px;
  height: 25px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
   